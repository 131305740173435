export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();

  // Check if the date is today
  const isToday = date.toDateString() === today.toDateString();

  if (isToday) {

    // If today, return time in hh:mm format
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else {

    // If not today, return the date in MM/DD/YYYY format (or customize as needed)
    return date.toLocaleDateString();
  }
};
import PropTypes from 'prop-types';
import React from 'react';

const ChatHeader = ({ selectedGroup, groupChats }) => {

  // Get unique users from groupChats
  const getUniqueUsers = (chats) => {
    const usersMap = {};
    chats.forEach(chat => {
      if (!usersMap[chat.user.id]) {
        // Add user to map if not already present
        usersMap[chat.user.id] = chat.user;
      }
    });
    return Object.values(usersMap); // Return the unique users
  };

  // Extract unique users
  const uniqueUsers = getUniqueUsers(groupChats);

  return (
    <div className="card-header border-bottom" id="kt_chat_messenger_header">
      <div className="card-title">
        <h5 className='m-0 me-3'>{selectedGroup.name}</h5>

        {/* Users */}
        <div className="symbol-group symbol-hover">
          {uniqueUsers.slice(0, 5).map((user, index) => (
            <div key={index} className="symbol symbol-35px symbol-circle">
              {user.avatar ? (
                <img alt={user.name} src={user.avatar} />
              ) : (
                <span className="symbol-label bg-light-primary text-primary">
                  {user.name.charAt(0).toUpperCase()} {/* Display first initial of user's name */}
                </span>
              )}
            </div>
          ))}

          {/* Link to view more users */}
          {uniqueUsers.length > 5 && (
            <button className="btn symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
              <span className="symbol-label fs-8 fw-bold" data-bs-toggle="tooltip" data-bs-trigger="hover" title="View more users">
                +{uniqueUsers.length - 5}
              </span>
            </button>
          )}

        </div>
      </div>
    </div>
  );
};

export default ChatHeader;

// Add prop-types validation
ChatHeader.propTypes = {
  selectedGroup: PropTypes.object,
  groupChats: PropTypes.array
};
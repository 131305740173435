import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { clientRoutes } from './Helpers';

const ProtectedRoute = () => {
  const isAuthenticated = !!Cookies.get('user');

  return isAuthenticated ? <Outlet /> : <Navigate to={clientRoutes.login} />;
};

export default ProtectedRoute;

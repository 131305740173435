import React, { useState } from 'react'
import LeftSide from './LeftSide'
import PropTypes from 'prop-types';

const LoginIndex = ({ onLogin, errorMessages, isLoading }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    if (!isLoading) {
      event.preventDefault();
      onLogin(email, password);
    }
  };

  return (
    <div className="d-flex flex-column flex-root full-height" id="kt_app_root">

      {/* Authentication - Sign-in */}
      <div className="d-flex flex-column flex-lg-row flex-column-fluid flex-lg-row-reverse">
        <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">
            <div className="w-lg-500px p-10">

              {/* Form */}
              <form className="form w-100" id="kt_sign_in_form" onSubmit={handleSubmit}>

                {/* Heading */}
                <div className="text-center mb-11">
                  <h1 className="text-gray-900 fw-bolder mb-3">Sign In</h1>
                </div>

                {/* Email and Password */}
                <div className="fv-row mb-8">
                  <input type="email" placeholder="Email" name="email" autoComplete="off" className="form-control bg-transparent" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="fv-row mb-3">
                  <input type="password" placeholder="Password" name="password" autoComplete="off" className="form-control bg-transparent" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>

                {/* Submit button */}
                <div className="d-grid mt-10 mb-10">
                  <button type="submit" id="kt_sign_in_submit" className="submit-btn" disabled={isLoading}>
                    {isLoading
                      ? <span className="indicator-label">Loading...</span>
                      : <span className="indicator-label">Sign In</span>
                    }
                  </button>
                </div>
              </form>

              {/* Render error messages below the form */}
              {errorMessages?.length > 0 && (
                <div className="alert alert-danger">
                  <ul className='m-0'>
                    {errorMessages.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}

            </div>
          </div>
        </div>

        {/* Aside */}
        <LeftSide />
      </div>
    </div>
  )
}

export default LoginIndex

LoginIndex.propTypes = {
  onLogin: PropTypes.func,
  errorMessages: PropTypes.array,
  isLoading: PropTypes.bool
};
import { serverPaths } from "./Helpers";

// Function to send a message to a specific group
export const sendMessageAction = async ({ selectedGroupId, message, token }) => {
  const payload = {
    content: message,
    message_type: "text",
    parent_id: null,
  };

  const response = await fetch(serverPaths.selectedGroupMessages(selectedGroupId), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  return response.json(); // Return the parsed JSON response
};

// Function to fetch messages from a specific group
export const fetchGroupMessagesAction = async (groupId, token) => {
  const response = await fetch(serverPaths.groupMessages(groupId), {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  return response.json(); // Return the parsed JSON response
};

// Function to fetch all groups
export const fetchGroupsAction = async (token) => {
  const response = await fetch(serverPaths.groups, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  return response.json(); // Return the parsed JSON response
};

// Function to handle user login
export const loginAction = async (credentials) => {
  const response = await fetch(serverPaths.login, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  });

  return response.json(); // Return the parsed JSON response
};

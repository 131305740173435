import React, { useState } from "react";
import { formatDate } from "../../Common/Services";
import PropTypes from "prop-types";

const Contacts = ({ groups, onGroupSelect }) => {
  const [searchTerm, setSearchTerm] = useState(""); // State to manage search input

  // Filter groups based on the search term
  const filteredGroups = groups.filter((group) =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
      <div className="contacts-card">
        <div className="card-header pt-7" id="kt_chat_contacts_header" style={{ border: "none" }}>
          <form className="w-100 position-relative d-flex align-items-center" autoComplete="off">
            <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input type="text" className="form-control form-control-solid px-13 bg-secondary" name="search" placeholder="Search by group name..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </form>
        </div>
        <div className="card-body pt-5" id="kt_chat_contacts_body">
          <div className="me-n5 pe-5 h-200px h-lg-auto" style={{ overflow: "auto" }}>
            {filteredGroups.length === 0 ? (
              <div className="text-center text-muted">No groups found.</div>
            ) : (
              filteredGroups.map((group, index) => (
                <div key={index} className="d-flex flex-stack py-4 cursor-pointer" onClick={() => onGroupSelect(group)} title={group.name}>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px symbol-circle">
                      {group.avatar ? (
                        <img alt="Group" src={group.avatar} />
                      ) : (
                        <div className="symbol-label bg-light-primary text-primary">
                          {group.name.split("")[0]}
                        </div>
                      )}
                    </div>
                    <div className="ms-5">

                      {/* Group Name (with line clamp) */}
                      <div className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2 line-clamp-1">
                        {group.name}
                      </div>

                      {/* Group Description (with line clamp) */}
                      <div className="fw-semibold text-muted line-clamp-1">
                        {group.description}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-end ms-2">
                    <span className="text-muted fs-7 text-end">
                      {formatDate(group.updated_at)}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

Contacts.propTypes = {
  groups: PropTypes.array,
  onGroupSelect: PropTypes.func
};
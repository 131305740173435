import React from 'react'
import { clientRoutes } from '../../Common/Helpers'
import { Link } from 'react-router-dom'
import Custom1 from "../../../assets/media/Logo/chat.jpg";
import AuthBg from "../../../assets/media/misc/auth-bg.png";

const LeftSide = () => {
  return (
    <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center" style={{ backgroundImage: `url(${AuthBg})` }}>
      <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">

        {/* Logo */}
        <Link to={clientRoutes.home} className="mb-0 mb-lg-12 text-white display-1">
          LOGO
        </Link>

        {/* Content */}
        <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src={Custom1} alt="" />
      </div>
    </div>
  )
}

export default LeftSide
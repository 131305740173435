import { useEffect } from 'react'
import { clientRoutes } from '../Common/Helpers';
import { useNavigate } from 'react-router-dom';

const HomeIndex = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page
    navigate(clientRoutes.login);
  }, [navigate]);

  return null;
}

export default HomeIndex
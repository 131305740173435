import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const ChatMessages = ({ chats, currentUserId }) => {
  const messagesEndRef = useRef(null); // Reference to track the end of messages

  // Scroll to the bottom of the chat container when new messages are added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chats]);

  return (
    <div className="">
      {/* Messages */}
      <div className="me-n5 pe-5" style={{ height: "calc(100dvh - 390px)", overflow: "auto", display: "flex", flexDirection: "column-reverse" }}>

        {/* Ref to keep track of the bottom of the chat */}
        <div ref={messagesEndRef} />

        {chats.length === 0 ? (
          <div className="text-center text-muted">Please select a group to start chatting.</div>
        ) : (
          chats.slice().map((chat, index) => {
            const isCurrentUser = chat.user_id === currentUserId; // Check if the current message is from the logged-in user
            const timestamp = new Date(chat.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            return (
              <div key={index} className={`d-flex justify-content-${isCurrentUser ? 'end' : 'start'} mb-10`}>
                <div className={`d-flex flex-column align-items-${isCurrentUser ? 'end' : 'start'}`}>
                  <div className={`d-flex align-items-center mb-2 ${isCurrentUser ? 'flex-row' : 'flex-row-reverse'}`}>
                    <div className={`d-flex align-items-center ${isCurrentUser ? 'me-3 flex-row' : 'ms-3 flex-row-reverse'}`}>
                      <span className={`text-muted fs-7 ${isCurrentUser ? 'me-3' : 'ms-3'}`}>{timestamp}</span>
                      <button className="btn p-0 fs-5 fw-bold text-gray-900 text-hover-primary">
                        {isCurrentUser ? 'You' : chat.user.name}
                      </button>
                    </div>
                    <div className="symbol symbol-35px symbol-circle">
                      {chat.user.avatar
                        ? <img alt="User Avatar" src={chat.user.avatar} />
                        : <div className="symbol-label bg-light-primary text-primary">{chat.user.name.charAt(0)}</div>
                      }
                    </div>
                  </div>
                  <div className={`p-5 rounded ${isCurrentUser ? 'bg-light-primary' : 'bg-light-info'} text-gray-900 fw-semibold mw-lg-400px text-${isCurrentUser ? 'end' : 'start'}`} data-kt-element="message-text">
                    {chat.content}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ChatMessages;

ChatMessages.propTypes = {
  chats: PropTypes.array,
  currentUserId: PropTypes.any
};
export const projectBaseName = "/"

const auth = "/auth";
const main = "/main";

export const clientRoutes = {
  home: "/",
  auth: auth,
  main: main,
  login: `${auth}/login`,
  chat: `${main}/chat`,
  notFound: "*",
}

// Server domain from env file
const serverDomain = process.env.REACT_APP_SERVER_DOMAIN;

// Ably API key from env file
export const ablyApiKey = process.env.REACT_APP_ABLY_API_KEY;

// Base server API
export const apiBasePath = `${serverDomain}/api`;

// Server paths
export const serverPaths = {
  login: `${apiBasePath}/login`,
  groups: `${apiBasePath}/groups`,
  groupMessages: (groupId) => `${apiBasePath}/groups/${groupId}/messages`,
  selectedGroupMessages: (selectedGroupId) => `${apiBasePath}/groups/${selectedGroupId}/messages`,
};
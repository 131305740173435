import React from 'react'

const Loader = () => {
  return (
    <div className="full-screen-overlay d-flex justify-content-center align-items-center">
      <span className="loader"></span>
    </div>
  )
}

export default Loader
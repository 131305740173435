import React, { useEffect, useState } from 'react';
import Contacts from './Contacts';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';
import ChatFooter from './ChatFooter';
import { useQuery } from '@tanstack/react-query';
import Loader from '../../Common/Loader';
import PropTypes from 'prop-types';
import { ablyApiKey } from '../../Common/Helpers';
import Ably from 'ably';
import { fetchGroupMessagesAction } from '../../Common/Actions';

const ChatIndex = ({ user, initialGroups }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupChats, setGroupChats] = useState([]);
  const [groups, setGroups] = useState([]);  // Store groups state locally for updates

  const ably = new Ably.Realtime({ key: ablyApiKey });

  useEffect(() => {
    const sortedUpdatedGroups = initialGroups.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    setGroups(sortedUpdatedGroups);
  }, [initialGroups]);

  useEffect(() => {
    if (selectedGroup) {
      const channel = ably.channels.get(`group-${selectedGroup.id}`);

      // Subscribing to the new message event
      const subscribeToChannel = () => {
        channel.subscribe('new-message', (message) => {
          const newMessageData = message.data;

          // Update group chats with the new message
          setGroupChats((prevChats) => [newMessageData, ...prevChats]);

          // Update the group's updated_at field with current time
          const updatedGroups = groups.map((group) => {
            if (group.id === selectedGroup.id) {
              return { ...group, updated_at: new Date().toISOString() };  // Update the group's time
            }
            return group;
          });
          const sortedUpdatedGroups = updatedGroups.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

          setGroups(sortedUpdatedGroups); // Update the groups state
        });
      };

      subscribeToChannel();

      // Clean up when the component is unmounted or group changes
      return () => {
        channel.unsubscribe();
        // Instead of channel.detach(), it's sufficient to unsubscribe to avoid the error
      };
    }
  }, [selectedGroup, ably.channels, groups]);


  // Fetch group chats using React Query
  const { data: chatsData, isLoading, isError, error } = useQuery({
    queryKey: ['groupChats', selectedGroup?.id], // Key based on selected group ID
    queryFn: () => fetchGroupMessagesAction(selectedGroup.id, user.token), // Fetch function
    enabled: !!selectedGroup, // Only fetch if a group is selected
    onSuccess: (data) => {
      setGroupChats(data.messages); // Set group chats on success
    },
    onError: (error) => {
      console.error('Error fetching group chats:', error);
    },
  });

  useEffect(() => {
    if (chatsData) {
      setGroupChats(chatsData.messages);
    }
  }, [chatsData]);

  const handleGroupSelect = (group) => {
    if (!(selectedGroup?.id === group.id)) {
      setSelectedGroup(group);
    }
  };

  const handleMessageSent = (newMessage) => {
    // Add the new message to the chat list
    setGroupChats([newMessage, ...groupChats]);

    // Update the selected group's updated_at field with current time
    const updatedGroups = groups.map((group) => {
      if (group.id === selectedGroup.id) {
        return { ...group, updated_at: new Date().toISOString() };
      }
      return group;
    });

    const sortedUpdatedGroups = updatedGroups.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

    setGroups(sortedUpdatedGroups);
  };

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      {(isLoading || isLoading) && (<Loader />)}
      <div id="kt_app_content_container" className="app-container container-xxl">
        <div className="d-flex flex-column flex-lg-row">

          {/* Sidebar */}
          <Contacts groups={groups} onGroupSelect={handleGroupSelect} />

          {/* Content */}
          <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10" style={{ height: "calc(100dvh - 135px)", minHeight: "500px" }}>
            <div className="contacts-card" id="kt_chat_messenger">

              {/* Card header */}
              {selectedGroup && !isLoading && groupChats?.length > 0 && (
                <ChatHeader selectedGroup={selectedGroup} groupChats={groupChats} />
              )}

              {/* Card body */}
              <div className="card-body p-4">
                <div className="text-center mt-4">
                  {isLoading && <p className="text-info fw-semibold">Loading chats...</p>}
                  {isError && <p className="text-danger fw-semibold">{error.message}</p>}
                  {!selectedGroup && (
                    <p className="text-warning fw-semibold">Please select a group to view chats.</p>
                  )}
                  {selectedGroup && !isLoading && !isError && groupChats?.length === 0 && (
                    <p className="text-muted fw-semibold">No messages available for this group.</p>
                  )}
                  {selectedGroup && !isLoading && groupChats?.length > 0 && (
                    <ChatMessages chats={groupChats} currentUserId={user.id} />
                  )}
                </div>
              </div>

              {/* Card footer */}
              {selectedGroup && <ChatFooter selectedGroup={selectedGroup} token={user.token} onMessageSent={handleMessageSent} ably={ably} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatIndex;

ChatIndex.propTypes = {
  user: PropTypes.object,
  initialGroups: PropTypes.array,
  ably: PropTypes.object
};

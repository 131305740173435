import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { clientRoutes } from '../Components/Common/Helpers';

const Auth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page
    navigate(clientRoutes.login);
  }, [navigate]);

  return null;
}

export default Auth
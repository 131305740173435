import React from 'react';
import { Link } from 'react-router-dom';
import { clientRoutes } from './Helpers';

const NotFound = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-light">
      <h1 className="display-1 text-danger">404</h1>
      <h2 className="mb-3 text-black">Page Not Found</h2>
      <p className="mb-4 text-muted">The page you are looking for might have been removed or is temporarily unavailable.</p>

      {/* Back to home */}
      <Link to={clientRoutes.home} className="btn btn-primary">
        Go Back Home
      </Link>
    </div>
  );
}

export default NotFound;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clientRoutes } from "../Components/Common/Helpers";

const Main = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page
    navigate(clientRoutes.home);
  }, [navigate]);

  return null;
}

export default Main
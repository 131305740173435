import React, { useEffect, useState } from 'react';
import ChatIndex from '../Components/Main/Chat/ChatIndex';
import { useNavigate } from 'react-router-dom';
import { clientRoutes } from '../Components/Common/Helpers';
import Cookies from 'js-cookie';
import { useQuery } from '@tanstack/react-query';
import { fetchGroupsAction } from '../Components/Common/Actions';

const Chat = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (!userCookie) {
      navigate(clientRoutes.login);
    } else {
      // Parse the user cookie to get user data
      const tempUser = JSON.parse(userCookie);
      setUser(tempUser);
    }
  }, [navigate]);

  // Fetch groups using React Query
  const { data: groupsData, isLoading, isError } = useQuery({
    queryKey: ['groups', user?.token],
    queryFn: () => fetchGroupsAction(user.token),
    enabled: !!user?.token, // Fetch only if token is available
    onSuccess: (data) => {
      console.log('Fetched groups successfully:', data.groups);
    },
    onError: (error) => {
      console.error('Error fetching groups:', error);
    },
  });

  return (
    <ChatIndex user={user}
      initialGroups={isLoading ? [] : (isError ? [] : (groupsData ? groupsData?.groups : []))}
    />
  );
};

export default Chat;
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { clientRoutes, projectBaseName } from "./Components/Common/Helpers";
import Login from "./Pages/Login";
import Chat from "./Pages/Chat";
import NotFound from "./Components/Common/NotFound";
import Home from "./Pages/Home";
import ProtectedRoute from "./Components/Common/ProtectedRoute";
import './assets/plugins/global/plugins.bundle.css';
import './assets/css/style.bundle.css';
import './assets/plugins/custom/datatables/datatables.bundle.css';
import AuthLayout from "./Layout/AuthLayout";
import MainLayout from "./Layout/MainLayout";
import Auth from "./Pages/Auth";
import Main from "./Pages/Main";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={projectBaseName}>
        <Routes>

          {/* Auth Routes */}
          <Route path={clientRoutes.auth} element={<Auth />} />
          <Route element={<AuthLayout />}>
            <Route path={clientRoutes.login} element={<Login />} />
          </Route>

          {/* Main Routes - Protected */}
          <Route path={clientRoutes.main} element={<Main />} />
          <Route element={<ProtectedRoute />}>
            <Route element={<MainLayout />}>
              <Route path={clientRoutes.home} element={<Home />} />
              <Route path={clientRoutes.chat} element={<Chat />} />
            </Route>
          </Route>

          {/* Catch-all for 404 */}
          <Route path={clientRoutes.notFound} element={<NotFound />} />

        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useMutation } from '@tanstack/react-query';
import LoginIndex from '../Components/Auth/Login/LoginIndex';
import { loginAction } from '../Components/Common/Actions';
import { clientRoutes } from '../Components/Common/Helpers';

const Login = () => {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState([]);

  const mutation = useMutation({
    mutationFn: loginAction,
    onSuccess: (data) => {
      if (data.errors) {
        // Handle validation errors
        setErrorMessages(data.errors.email);
        return;
      }

      if (data.message === "Invalid credentials.") {
        setErrorMessages([data.message]);
        return;
      }

      // Store user in a cookie, which expires in 7 days
      Cookies.set('user', JSON.stringify({ ...data.user, token: data.token }), { expires: 7 });

      // Redirect to chat
      navigate(clientRoutes.chat);
    },
    onError: (error) => {
      setErrorMessages([error?.message || 'An unexpected error occurred.']);
    },
  });

  const handleLogin = (email, password) => {
    setErrorMessages([]);
    mutation.mutate({ email, password });
  };

  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (userCookie) {
      navigate(clientRoutes.chat);
    }
  }, [navigate]);

  return (
    <LoginIndex onLogin={handleLogin} isLoading={mutation.isPending}
      errorMessages={mutation.isError ? [mutation.error?.message || 'An unexpected error occurred.'] : errorMessages}
    />
  );
};

export default Login;
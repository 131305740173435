import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query'; // Import useMutation
import { sendMessageAction } from '../../Common/Actions';
import PropTypes from 'prop-types';

const ChatFooter = ({ selectedGroup, token, onMessageSent, ably }) => {
  const [message, setMessage] = useState(""); // State to store the message input
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const [errorMessage, setErrorMessage] = useState(''); // State to track error messages

  const channel = ably.channels.get(`group-${selectedGroup.id}`);

  // Use React Query's useMutation to handle sending messages
  const mutation = useMutation({
    mutationFn: sendMessageAction,
    onSuccess: (newMessage) => {
      channel.publish('new-message', newMessage.data);
      onMessageSent(newMessage.data); // Update chat with the new message
      setMessage(""); // Clear the input field
      setErrorMessage(''); // Clear previous error messages
    },
    onError: (error) => {
      console.error("Error sending the message:", error.message);
      setErrorMessage(error.message); // Set the error message to display
    },
  });

  const handleInputChange = (e) => {
    setMessage(e.target.value); // Update the message input state
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!message.trim()) return; // Don't send empty messages

    if (!(isLoading || mutation.isPending)) {
      setIsLoading(true);

      // Call the mutation to send the message
      mutation.mutate({ selectedGroupId: selectedGroup.id, message, token });
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e); // Trigger message submission
    }
  };

  return (
    <div className="card-footer pt-4">

      <form onSubmit={sendMessage} className='d-flex'>

        {/* Input Area */}
        <textarea className="form-control form-control-flush border scrollbar-none me-3" rows="1" placeholder="Type a message" value={message} onChange={handleInputChange} onKeyDown={handleKeyPress} />

        <div className="d-flex flex-stack">
          {/* Send Button */}
          <button className="btn btn-primary" type="submit" disabled={isLoading || mutation.isPending}>
            {(isLoading || mutation.isPending) ? 'Sending...' : 'Send'}
          </button>
        </div>

      </form>

      {/* Display error message if exists */}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </div>
  );
};

export default ChatFooter;

ChatFooter.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  onMessageSent: PropTypes.func.isRequired,
  ably: PropTypes.object.isRequired,
};

// Header.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FaSignOutAlt } from 'react-icons/fa';
import { clientRoutes } from '../../Common/Helpers';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {

    // Clear user-related cookies
    Cookies.remove('user');

    // Redirect to login
    navigate(clientRoutes.login);
  };

  // Retrieve user data from cookies and parse it
  const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;

  return (
    <header className="navbar navbar-expand-lg navbar-light bg-secondary">
      <div className="container">
        <div className="w-100">
          <div className="ml-auto d-flex align-items-center w-100 justify-content-between">
            <div className="me-2 d-flex align-items-center">
              {user && user.avatar ? (
                <img alt={user.name} src={user.avatar} className="rounded-circle" style={{ width: '50px', height: '50px' }} />
              ) : (
                <span className="symbol-label bg-light-dark text-primary rounded-circle d-flex align-items-center justify-content-center" style={{ width: '30px', height: '30px' }}>
                  {user ? user.name.charAt(0).toUpperCase() : <></>}
                </span>
              )}
              <span className="ms-2">{user ? user.name : 'Guest'}</span>
            </div>
            <button className="btn" onClick={handleLogout} title='Logout'>
              <FaSignOutAlt className="me-1" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
